import Keycloak from "keycloak-js";

type TokenParsed = {
    preferred_username?: string;
    given_name?: string;
    family_name?: string;
    email?: string;
};

export type KeycloakInstance = Keycloak & {
    tokenParsed?: TokenParsed;
};

const KEYCLOAK_URL = import.meta.env.VITE_KEYCLOAK_URL as string;
const KEYCLOAK_REALM = import.meta.env.VITE_KEYCLOAK_REALM as string;
const KEYCLOAK_CLIENT = import.meta.env.VITE_KEYCLOAK_CLIENT as string;


const keycloakInstance: KeycloakInstance = new Keycloak({
    url: KEYCLOAK_URL,
    realm: KEYCLOAK_REALM,
    clientId: KEYCLOAK_CLIENT
});

const mockLogin = (onAuthenticatedCallback: () => void): void => {
    // Mock authentication in development mode
    keycloakInstance.authenticated = true;
    keycloakInstance.tokenParsed = {
        preferred_username: "devuser",
        given_name: "Dev",
        family_name: "User",
        email: "devuser@example.com",
    };
    onAuthenticatedCallback();
};

const Login = async (onAuthenticatedCallback: () => void): Promise<void> => {
    if (process.env.NODE_ENV === 'development') {
        mockLogin(onAuthenticatedCallback);
        return;
    }

    try {
        const authenticated = await keycloakInstance.init({
            onLoad: "login-required",
            pkceMethod: 'S256'
        });

        console.log('KeycloakService.Login.keycloakInstance.init idToken: ', keycloakInstance.idToken);

        if (authenticated) {
            localStorage.setItem('kc_token', keycloakInstance.token || '');
            localStorage.setItem('kc_idToken', keycloakInstance.idToken || '');
            localStorage.setItem('kc_tokenParsed', JSON.stringify(keycloakInstance.tokenParsed || {}));
            localStorage.setItem('kc_email', keycloakInstance.tokenParsed?.email || "n/a");
            onAuthenticatedCallback();
        } else {
            await keycloakInstance.login();
        }
    } catch (e: unknown) {
        console.dir(e);
        console.log(`keycloak init exception: ${String(e)}`);
        throw e;
    }
};

const UserName = (): string | undefined => keycloakInstance.tokenParsed?.preferred_username;
const Token = (): string | undefined => keycloakInstance.token;
const TokenParsed = (): TokenParsed | undefined => keycloakInstance.tokenParsed;
const Authenticated = (): boolean => keycloakInstance.authenticated;
const FirstName = (): string | undefined => keycloakInstance.tokenParsed?.given_name;
const LastName = (): string | undefined => keycloakInstance.tokenParsed?.family_name;
const Email = (): string | undefined => keycloakInstance.tokenParsed?.email;
const OIDCToken = (): string | undefined => keycloakInstance.idToken;
const Logout = (): Promise<void> => {
    return keycloakInstance.logout({
        redirectUri: 'https://shb.ais.ucla.edu/shibboleth-idp/Logout',
    });
};

const KeyCloakService = {
    keycloakInstance,
    CallLogin: Login,
    IsAuthenticated: Authenticated,
    GetToken: Token,
    GetUserName: UserName,
    GetUserFirstName: FirstName,
    GetUserLastName: LastName,
    GetUserEmail: Email,
    GetTokenParsed: TokenParsed,
    GetOIDCToken: OIDCToken,
    CallLogout: Logout
};

export default KeyCloakService;
