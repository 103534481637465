// AttendeeList.tsx
/* eslint-disable */
import React, { useState, useEffect, useCallback, memo } from 'react';
import { Registration, UpdateRegistrationInput, RegistrationStatusEnum } from './API';
import { updateRegistrationDetails } from './mutations/processUpdateRegistration';
import DownloadModal from './DownloadModal';
import Switch from "react-switch";
import { Link } from 'react-router-dom';
import './css/AttendeeList.css';

const ROWS_PER_PAGE = 25;

interface AttendeeListProps {
  attendees: Registration[];
  setAttendees: React.Dispatch<React.SetStateAction<Registration[]>>;
}

// Debouncing Hook
function useDebouncedValue<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}

function AttendeeList(props: AttendeeListProps) {
  const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedQuery = useDebouncedValue(searchQuery, 300);
  const [testToggled, setTestToggled] = useState<boolean>(false);
  const [invitedToggled, setInvitedToggled] = useState<boolean>(true);
  const [statusFilter, setStatusFilter] = useState<string>('ALL');
  const [selectedAttendees, setSelectedAttendees] = useState<Set<string>>(new Set());
  const [bulkAction, setBulkAction] = useState<string>('');
  const [rowActions, setRowActions] = useState<Record<string, number>>({});
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [selectedDownloadFormat, setSelectedDownloadFormat] = useState<string>('csv');

  // First, filter and sort the full list
  const filteredAttendees = [...props.attendees]
    .sort((a, b) => {
      if (sortConfig !== null) {
        const { key, direction } = sortConfig;
        let aValue = a[key];
        let bValue = b[key];

        // Handle string comparison
        if (typeof aValue === 'string') aValue = aValue.toLowerCase();
        if (typeof bValue === 'string') bValue = bValue.toLowerCase();

        if (aValue < bValue) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      }
      return 0;
    })
    .filter(attendee => {
      const query = debouncedQuery.toLowerCase();
      const firstName = attendee.firstName ? attendee.firstName.toLowerCase() : '';
      const lastName = attendee.lastName ? attendee.lastName.toLowerCase() : '';
      const email = attendee.email ? attendee.email.toLowerCase() : '';
      const fullName = `${firstName} ${lastName}`;
      const matchesQuery = fullName.includes(query) || email.includes(query);
      const showTestRegistrations = testToggled || attendee.live;
      const showInvitedRegistrations = invitedToggled || attendee.registrationStatus !== RegistrationStatusEnum.INVITED;
      const matchesStatus = statusFilter === 'ALL' || attendee.registrationStatus === statusFilter;
      return matchesQuery && showTestRegistrations && showInvitedRegistrations && matchesStatus;
    })
    .map(attendee => {
      let membership = '';
      try {
        if (attendee.additionalData && attendee.additionalData.trim() !== '') {
          const unescapedData = JSON.parse(attendee.additionalData);
          const parsedData = JSON.parse(unescapedData);
          membership = parsedData.membership || '';
        }
      } catch (error) {
        console.error('Error parsing additionalData for attendee:', attendee.email, error);
      }
      return {
        ...attendee,
        membership,
        guests: attendee.guests || 0,
        checkedInCount: attendee.checkedInCount ?? 0,
      };
    });

  // Then, apply pagination to the filtered list
  const paginatedAttendees = filteredAttendees.slice(currentPage * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE + ROWS_PER_PAGE);

  const totalAttendees = filteredAttendees.length;
  const totalPages = Math.ceil(totalAttendees / ROWS_PER_PAGE);

  const handleSubmit = async (newCheckedInCount: number, attendee: Registration) => {
    const registrationData: UpdateRegistrationInput = {
      eventId: attendee.eventId,
      registrationId: attendee.registrationId,
      checkedInCount: newCheckedInCount,
      registrationStatus: newCheckedInCount > 0
        ? (newCheckedInCount === attendee.guests || attendee.guests === 0
          ? RegistrationStatusEnum.CHECKED_IN
          : RegistrationStatusEnum.PARTIAL_CHECK_IN)
        : RegistrationStatusEnum.REGISTERED
    };

    try {
      const response = await updateRegistrationDetails(registrationData);
      if (response && response.checkedInCount === newCheckedInCount) {
        // Update the attendees state to reflect the new status
        props.setAttendees(prevAttendees =>
          prevAttendees.map(a =>
            a.registrationId === attendee.registrationId
              ? { ...a, checkedInCount: newCheckedInCount, registrationStatus: registrationData.registrationStatus }
              : a
          )
        );
        // Reset the row action to "Select Action..."
        setRowActions(prevRowActions => ({
          ...prevRowActions,
          [attendee.registrationId]: 0,
        }));
      } else {
        console.error('Unexpected response structure or status mismatch:', response);
      }
      setActiveDropdownId(null);
    } catch (err) {
      console.error('Error updating registration:', err);
      setActiveDropdownId(null);
    }
  };

  const handleTestToggleChange = (checked: boolean) => {
    console.log('Toggling test registrations:', checked);
    setTestToggled(checked);
  }

  const handleInvitedToggleChange = (checked: boolean) => {
    console.log('Toggling invited registrations:', checked);
    setInvitedToggled(checked);
  }

  const toggleSelectAll = (checked: boolean) => {
    if (checked) {
      const allIds = filteredAttendees.map(attendee => attendee.registrationId);
      setSelectedAttendees(new Set(allIds));
    } else {
      setSelectedAttendees(new Set());
    }
  };

  // Function to handle toggling the selection of an individual attendee
  const toggleSelectOne = (id: string) => {
    const newSet = new Set(selectedAttendees);
    if (newSet.has(id)) {
      newSet.delete(id);
    } else {
      newSet.add(id);
    }
    setSelectedAttendees(newSet);
  };

  const handleBulkSubmit = async () => {
    const attendeesArray = Array.from(selectedAttendees);

    if (bulkAction === "CHECK_IN") {
      const confirmCheckIn = window.confirm(`You are about to check in ${attendeesArray.length} attendee(s). Confirm?`);
      if (!confirmCheckIn) return;

      const promises = attendeesArray.map(async (id) => {
        const attendee = props.attendees.find(a => a.registrationId === id);
        if (attendee) {
          const guests = attendee.guests && attendee.guests > 0 ? attendee.guests : 1;
          return handleSubmit(guests, attendee);
        }
      });
      await Promise.all(promises);
    } else if (bulkAction === "UNDO_CHECK_IN") {
      const confirmUndoCheckIn = window.confirm(`You are about to undo check-in for ${attendeesArray.length} attendee(s). Confirm?`);
      if (!confirmUndoCheckIn) return;

      const promises = attendeesArray.map(async (id) => {
        const attendee = props.attendees.find(a => a.registrationId === id);
        if (attendee) {
          return handleSubmit(0, attendee);
        }
      });
      await Promise.all(promises);
    } else if (bulkAction === "SUBMIT_DATA") {
      alert("Data upload successful. Selected attendees have been marked to be submitted to CRM by end of day.");
      props.setAttendees(prevAttendees =>
        prevAttendees.map(attendee => {
          if (selectedAttendees.has(attendee.registrationId)) {
            return { ...attendee, submittedToCRM: true };
          }
          return attendee;
        })
      );
    } else if (bulkAction === "DOWNLOAD") {
      setShowDownloadModal(true);
      return;
      // const downloadFormat = window.prompt("Please enter download format: 'csv' or 'excel' (cancel to abort)");
      // if (!downloadFormat) {
      //   return;
      // }
      // if (downloadFormat.toLowerCase() === 'csv') {
      //   const selectedData = props.attendees.filter(att => selectedAttendees.has(att.registrationId));
      //   if (selectedData.length === 0) {
      //     alert("No attendees selected for download.");
      //     return;
      //   }
      //   let csvContent = "data:text/csv;charset=utf-8,";
      //   const headers = ["RegistrationId", "Email", "FirstName", "LastName", "RegistrationStatus", "DateCreated"];
      //   csvContent += headers.join(",") + "\n";
      //   selectedData.forEach(att => {
      //     const row = [
      //       att.registrationId,
      //       att.email,
      //       att.firstName,
      //       att.lastName,
      //       att.registrationStatus,
      //       att.dateCreated
      //     ];
      //     csvContent += row.join(",") + "\n";
      //   });
      //   const encodedUri = encodeURI(csvContent);
      //   const link = document.createElement("a");
      //   link.setAttribute("href", encodedUri);
      //   link.setAttribute("download", "attendees.csv");
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      // } else if (downloadFormat.toLowerCase() === 'excel') {
      //   alert("Excel download is not yet implemented.");
      // } else {
      //   alert("Invalid download format specified.");
      // }
    }
  };

  const handleDownloadConfirm = () => {
    if (selectedDownloadFormat === 'csv') {
      const selectedData = props.attendees.filter(att => selectedAttendees.has(att.registrationId));
      if (selectedData.length === 0) {
        alert("No attendees selected for download.");
        setShowDownloadModal(false);
        return;
      }
      let csvContent = "data:text/csv;charset=utf-8,";
      const headers = ["RegistrationId", "Email", "FirstName", "LastName", "RegistrationStatus", "DateCreated"];
      csvContent += headers.join(",") + "\n";
      selectedData.forEach(att => {
        const row = [
          att.registrationId,
          att.email,
          att.firstName,
          att.lastName,
          att.registrationStatus,
          att.dateCreated
        ];
        csvContent += row.join(",") + "\n";
      });
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "attendees.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (selectedDownloadFormat === 'excel') {
      alert("Excel download is not yet implemented.");
    } else {
      alert("Invalid download format specified.");
    }
    setShowDownloadModal(false);
  };

  const handleSort = (columnKey: string) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortedAndFilteredAttendees = [...props.attendees]
    .sort((a, b) => {
      if (sortConfig !== null) {
        const { key, direction } = sortConfig;
        let aValue = a[key];
        let bValue = b[key];

        // Handle string comparison
        if (typeof aValue === 'string') aValue = aValue.toLowerCase();
        if (typeof bValue === 'string') bValue = bValue.toLowerCase();

        if (aValue < bValue) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      }
      return 0;
    })
    .filter(attendee => {
      const query = debouncedQuery.toLowerCase();
      const firstName = attendee.firstName ? attendee.firstName.toLowerCase() : '';
      const lastName = attendee.lastName ? attendee.lastName.toLowerCase() : '';
      const email = attendee.email ? attendee.email.toLowerCase() : '';
      const fullName = `${firstName} ${lastName}`;
      const matchesQuery = fullName.includes(query) || email.includes(query);
      const showTestRegistrations = testToggled || attendee.live;
      const showInvitedRegistrations = invitedToggled || attendee.registrationStatus !== RegistrationStatusEnum.INVITED;
      const matchesStatus = statusFilter === 'ALL' || attendee.registrationStatus === statusFilter;

      return matchesQuery && showTestRegistrations && showInvitedRegistrations && matchesStatus;
    })
    .slice(currentPage * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE + ROWS_PER_PAGE)
    .map(attendee => {
      let membership = '';

      try {
        if (attendee.additionalData && attendee.additionalData.trim() !== '') {
          const unescapedData = JSON.parse(attendee.additionalData);
          const parsedData = JSON.parse(unescapedData);
          membership = parsedData.membership || '';
        }
      } catch (error) {
        console.error('Error parsing additionalData for attendee:', attendee.email, error);
      }

      return {
        ...attendee,
        membership,
        guests: attendee.guests || 0,
        checkedInCount: attendee.checkedInCount ?? 0,
      };
    });

  const handleNextPage = () => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  const handlePreviousPage = () => setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));


  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
    return date.toLocaleString(undefined, options);
  };

  return (
    <>
      <div className='registrations-options-container'>
        <h2 className="registrations-options-header">Registrants</h2>
        <div className='registrations-status-container'>
          <p className='registrations-status-header-text'>Status:</p>
          <p className='registrations-status-value-text'>
            {props.attendees.filter((reg) => reg.registrationStatus === "CHECKED_IN" || reg.registrationStatus === "PARTIAL_CHECK_IN").length}/{props.attendees.length}
          </p>
        </div>
      </div>

      <div className='attendee-list-container'>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
          <h4 style={{ marginTop: '0rem', marginBottom: '0rem' }} className="attendee-header">Filter by Status</h4>
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="status-filter-dropdown"
          >
            <option value="ALL">All</option>
            <option value="REGISTERED">Registered</option>
            <option value="CHECKED_IN">Checked In</option>
            <option value="PARTIAL_CHECK_IN">Partial Check-In</option>
            <option value="CANCELLED">Cancelled</option>
            <option value="INVITED">Invited</option>
          </select>
        </div>

        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
          <h4 style={{ marginTop: '0rem', marginBottom: '0rem' }} className="attendee-header">Show Invited</h4>
          <Switch onChange={handleInvitedToggleChange} checked={invitedToggled} />
        </div> */}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
          <h4 style={{ marginTop: '0rem', marginBottom: '0rem' }} className="attendee-header">Show Test Registrations</h4>
          <Switch onChange={handleTestToggleChange} checked={testToggled} />
        </div>

        <input
          type="text"
          className="search-box"
          placeholder="Search by name or email..."
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className=''>
          <div className='actions-dropdown'>
            <select value={bulkAction} onChange={e => setBulkAction(e.target.value)}>
              <option value="" >Select Action...</option>
              {/* <option value="SUBMIT_DATA">Submit Data</option> */}
              <option value="DOWNLOAD">Download Selection</option>
              <option value="CHECK_IN">Check In</option>
              <option value="UNDO_CHECK_IN">Undo Check In</option>
            </select>
            <button onClick={handleBulkSubmit}>Submit</button>
          </div>

          <br />

          <table className="attendee-table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={e => toggleSelectAll(e.target.checked)}
                    checked={selectedAttendees.size === filteredAttendees.length}
                  />
                </th>
                <th onClick={() => handleSort('lastName')}>Name</th>
                <th onClick={() => handleSort('email')}>Email</th>
                <th onClick={() => handleSort('dateCreated')} className='hide-for-mobile'>Transaction date</th>
                <th>Status</th>
                {/* <th onClick={() => handleSort('membership')}>Membership</th> */}
                <th className='hide-for-mobile'>Guests</th>
                <th className='hide-for-mobile'>Check In</th>
                <th className='hide-for-desktop'>Check-In</th>
                <th className='hide-for-desktop'>More</th>
              </tr>
            </thead>

            <tbody>
              {paginatedAttendees.map(attendee => (
                <tr key={attendee.registrationId} className="attendee-row">
                  {/* className={`attendee-row ${attendee.submittedToCRM ? 'submitted-to-crm' : ''}`}> */}
                  <td>
                    <div className='input-row'>
                      <input
                        type='checkbox'
                        className='input-checkbox'
                        checked={selectedAttendees.has(attendee.registrationId)}
                        onChange={() => toggleSelectOne(attendee.registrationId)}
                      />
                    </div>
                  </td>
                  <td>
                    {attendee.submissionId ? (
                      <Link to={`https://reports.support2.ucla.edu/reports/datacapture/Report.aspx?${attendee.live ? '' : 'Test=Y&'}Indiv=Y&SubmissionID=${attendee.submissionId}`}>
                        {attendee.lastName}, {attendee.firstName}{!attendee.live && <b> - (TEST)</b>}
                      </Link>
                    ) : (
                      <>
                        {attendee.lastName}, {attendee.firstName}{!attendee.live && <b> - (TEST)</b>}
                      </>
                    )}
                  </td>
                  <td>{attendee.email}</td>
                  <td>{formatDate(attendee.dateCreated)}</td>
                  <td>
                    {attendee.registrationStatus} {
                      attendee.membership && attendee.membership !== null && attendee.membership !== ''
                        ? (<b>({attendee.membership})</b>)
                        : ''
                    }
                  </td>
                  {/* <td>{attendee.membership ? attendee.membership : ' '}</td> */}

                  <td>{`${attendee.checkedInCount} / ${attendee.guests}`}</td>
                  <td className=''>
                    <div className='actions-dropdown'>
                      <select
                        value={rowActions[attendee.registrationId] ?? attendee.checkedInCount}
                        onChange={(e) => {
                          const newActions = { ...rowActions, [attendee.registrationId]: Number(e.target.value) };
                          setRowActions(newActions);
                        }}
                        disabled={attendee.registrationStatus === 'CANCELLED'}
                      >
                        {/* If the registrationStatus is 'INVITED', show options 0-8 */}
                        {attendee.registrationStatus === RegistrationStatusEnum.INVITED || attendee.guests === 0
                          ? [...Array(9).keys()].map(count => (
                            <option key={count} value={count}>{count}</option>
                          ))
                          : [...Array(attendee.guests + 1).keys()].map(count => (
                            <option key={count} value={count}>{count}</option>
                          ))
                        }
                      </select>
                      <button
                        onClick={() => handleSubmit(rowActions[attendee.registrationId] ?? attendee.checkedInCount, attendee)}
                        disabled={attendee.registrationStatus === 'CANCELLED'}
                        style={{
                          backgroundColor: attendee.registrationStatus === 'CANCELLED' ? 'grey' : '',
                        }}
                      >
                        SAVE
                      </button>
                    </div>
                  </td>
                  <td className='hide-for-desktop'>
                  </td>
                  <td className='hide-for-desktop'>
                    <div className='button-container'>
                      {activeDropdownId === attendee.registrationId && (
                        <div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination Controls */}
          <div className="pagination-controls">
            <button onClick={handlePreviousPage} disabled={currentPage === 0}>Back</button>
            <span> Page {currentPage + 1} of {totalPages} </span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>Next</button>
          </div>
        </div>
      </div >
      {showDownloadModal && (
        <DownloadModal
          selectedDownloadFormat={selectedDownloadFormat}
          setSelectedDownloadFormat={setSelectedDownloadFormat}
          onConfirm={handleDownloadConfirm}
          onCancel={() => setShowDownloadModal(false)}
        />
      )}
    </>
  );
}

export default AttendeeList;
