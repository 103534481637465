/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useRouteLoaderData, useLoaderData, useParams } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import { Event, Registration } from './API';
import AttendeeList from './AttendeeList';
import ScanTicket from './ScanTicket';
import EventQr from './EventQr';
import FileUpload from "./FileUpload";
import NavBar from './NavBar';
import AddUser from './AddUserModal';
import { FaVideo, FaUserPlus } from "react-icons/fa";
import { ON_CREATE_REGISTRATION } from './graphql/subscriptions/onCreateRegistration';
import { ON_UPDATE_REGISTRATION } from './graphql/subscriptions/onUpdateRegistration';
import './css/Event.css';

const EventItem: React.FC = () => {
  // Fetch data from loaders
  const events = useRouteLoaderData("events") as Event[];
  const [registrations, setRegistrations] = useState<Registration[]>(useLoaderData() as Registration[]);
  const { eventId } = useParams();

  // State management
  const [scanTicket, setScanTicket] = useState(false);
  const [viewQr, setViewQr] = useState(false);
  const [showSelfRegistration, setShowSelfRegistration] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  // Get current event
  const event = events.find(e => e.eventId === eventId);
  if (!eventId || !event) return <div>Not Found</div>;

  const { eventName, location, date, description, eventRole } = event;

  // Subscriptions
  const { data: newRegistrationData, error: newRegistrationError } = useSubscription(ON_CREATE_REGISTRATION, {
    variables: { eventId },
  });

  const { data: updatedRegistrationData, error: updatedRegistrationError } = useSubscription(ON_UPDATE_REGISTRATION, {
    variables: { eventId },
  });

  // Handle new registrations
  useEffect(() => {
    if (newRegistrationError) {
      console.error('Error with new registration subscription:', newRegistrationError);
    }

    if (newRegistrationData) {
      const newRegistration = newRegistrationData?.onCreateRegistration as Registration;
      setRegistrations(prev => [...prev, newRegistration]);
    }
  }, [newRegistrationData, newRegistrationError]);

  // Handle updated registrations
  useEffect(() => {
    if (updatedRegistrationError) {
      console.error('Error with updated registration subscription:', updatedRegistrationError);
    }

    if (updatedRegistrationData) {
      const updatedRegistration = updatedRegistrationData?.onUpdateRegistration as Registration;
      setRegistrations(prev =>
        prev.map(reg => reg.registrationId === updatedRegistration.registrationId ? updatedRegistration : reg)
      );
    }
  }, [updatedRegistrationData, updatedRegistrationError]);

  // Event Handlers
  const startScanningTicket = () => setScanTicket(!scanTicket);
  const handleAddUserClick = () => setShowAddUserModal(true);
  const handleCloseAddUserModal = () => setShowAddUserModal(false);
  const readableDate = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return scanTicket ? (
    <ScanTicket
      eventId={eventId}
      handler={() => null} // Adjust based on scan handling requirements
      goBack={() => setScanTicket(false)}
      attendees={registrations}
      setAttendees={setRegistrations}
    />
  ) : viewQr ? (
    <EventQr event={event} setViewQr={setViewQr} />
  ) : (
    <div className="event-details">
      <div style={{ display: "flex", alignItems: "center" }}>
        <NavBar eventId={eventId} eventName={eventName} />
      </div>
      <h1 className="event-details-header">
        Event Details for <br />
        <b>{eventName}</b>
      </h1>
      <address>{location}</address>
      <div className="event-date">{readableDate}</div>
      <p>{description}</p>

      <button
        className="event-button"
        onClick={startScanningTicket}
        disabled={!registrations.length}
      >
        <FaVideo className="react-icons" /> Scan Tickets
      </button>

      {["OWNER", "COOWNER"].includes(eventRole) && (
        <div>
          <button className="event-button" onClick={handleAddUserClick}>
            <FaUserPlus className="react-icons" /> Add Users To Event Team
          </button>
        </div>
      )}

      {showSelfRegistration && (
        <>
          <div className="disclaimer-container">
            <p>
              * Only to be used for free events. Do not distribute this QR code
              or URL prior to the event.
            </p>
          </div>
          <button className="event-button" onClick={() => setViewQr(true)}>
            Self Sign in Site QR
          </button>
          <button
            className="event-button"
            onClick={() =>
              window.open(
                `http://events-registration-form-poc.s3-website-us-west-2.amazonaws.com/${eventId}`,
                '_blank'
              )
            }
          >
            Self Sign in Site URL
          </button>
        </>
      )}
      
      {/* TODO STILL only for DEV */}
      {/* {["OWNER", "COOWNER"].includes(eventRole) && (
        <FileUpload eventId={eventId} />
      )} */}

      <AttendeeList attendees={registrations} setAttendees={setRegistrations} />

      {showAddUserModal && (
        <AddUser eventDetails={event} onClose={handleCloseAddUserModal} />
      )}
    </div>
  );
};

export default EventItem;