import React from 'react';
import './css/Modal.css';

interface DownloadModalProps {
    selectedDownloadFormat: string;
    setSelectedDownloadFormat: (format: string) => void;
    onConfirm: () => void;
    onCancel: () => void;
}

const DownloadModal: React.FC<DownloadModalProps> = ({ selectedDownloadFormat, setSelectedDownloadFormat, onConfirm, onCancel }) => {
    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onCancel}>&times;</span>
                <h2>Select Download Format</h2>
                <select className="search-box" value={selectedDownloadFormat} onChange={(e) => setSelectedDownloadFormat(e.target.value)}>
                    <option value="csv">CSV</option>
                    <option value="excel">Excel</option>
                </select>
                <br />
                <div className='modal-buttons'>
                    <button className='modal-button' onClick={onConfirm}>Download</button>
                    <button className='modal-button' onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default DownloadModal;