// EventList.tsx
import { useRouteLoaderData } from 'react-router-dom';
import EventListItem from './EventListItem';
import { Event } from './API';
import './css/CreateEventModal.css';

function EventList() {
  const events = useRouteLoaderData("events") as Event[];

  return (
    <>
      <div className='dashboard-header-container'>
        <h1 className="dashboard-header">Your Managed Events</h1>
      </div>

      {events?.length === 0 ? (
        <div className="no-events-message">No events to show</div>
      ) : (
        events
          .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
          .map((event, key) => {
            if (event && event.live) {
              return <EventListItem event={event} key={key} />
            }
            return null; // In case the event is not live
          })
      )}
    </>
  );
}

export default EventList;